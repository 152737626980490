import { Service } from './service'

const PATH = 'account/emails'

class EmailsVerificationService extends Service {
    getEmailsData (params) {
        return this.get(PATH, params)
    }

    getCreationData () {
        return this.get(PATH + '/creation-data')
    }

    getDns (id) {
        return this.get(PATH + '/dns/' + id)
    }

    getDomain (params) {
        return this.post(PATH + '/get-domain', params)
    }

    getAwsStatuses (params) {
        return this.post(PATH + '/get-statuses', params)
    }

    checkDomainExists (params) {
        return this.post(PATH + '/domain-exists', params)
    }

    addDomain (params) {
      return this.post(PATH + '/add-domain', params)
    }

    verifyDomain (params) {
      return this.post(PATH + '/verify-domain', params)
    }

    verifyDomainTxt (params) {
      return this.post(PATH + '/verify-txt-domain', params)
    }

    verifyDomainMx (params) {
      return this.post(PATH + '/verify-mx-domain', params)
    }

    verifyDomainDkim (params) {
      return this.post(PATH + '/verify-dkim-domain', params)
    }

    isAvailableEmail (params) {
        return this.post(PATH + '/available-email', params)
    }

    insertEmailAddress (params) {
        return this.post(PATH + '/add-email-address', params)
    }

    verifyEmailAddress (params) {
        return this.post(PATH + '/verify', params)
    }

    deleteEmailAddress (params) {
        return this.delete(PATH + '/delete/' + params)
    }

    verifyReplyEmailAddress (params) {
        return this.post(PATH + '/reply/verify', params)
    }

    deleteReplyEmailAddress (params) {
        return this.delete(PATH + '/reply/delete/' + params)
    }

    migrateDomainToV2 (params) {
        return this.post(PATH + '/migrate-v2', params)
    }
}

export default new EmailsVerificationService()
