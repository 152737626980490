
export default {
  name: 'EmailMigrationConfirmationDialog',
  props: {
    toogle: {
      type: Boolean,
      default: false,
    },
    domain: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'confirm'],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm')
    },
  },
  created () {
  },
}
